import React, { createContext, useReducer } from "react"
import { gsap } from "gsap"
import { Store } from "utils/Store"
import { setTheme } from "utils/Theme"

export const initialState = {
  theme: typeof window !== "undefined" && window.localStorage.getItem("theme"),
  isLoaded: false,
  logoMesh: null,
  sparks: null,
  isMobile: null,
  currentTimelineDuration: 0,
  homeTl: gsap.timeline({ paused: true }),
  aboutTl: gsap.timeline({ paused: true }),
  worksTl: gsap.timeline({ paused: true }),
  projectTl: gsap.timeline({ paused: true }),
}

const changeTheme = state => {
  const theme = state.theme === "theme-dark" ? "theme-light" : "theme-dark"
  setTheme(theme)
  Store.theme = theme
  return {
    ...state,
    theme,
  }
}

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_THEME":
      return changeTheme(state)
    case "SET_LOADED":
      return { ...state, isLoaded: action.value }
    case "SET_LOGOMESH":
      return { ...state, logoMesh: action.value }
    case "SET_SPARKS":
      return { ...state, sparks: action.value }
    case "SET_MOBILE":
      return { ...state, isMobile: action.value }
    case "SET_CURRENT_TIMELINE_DURATION":
      return { ...state, currentTimelineDuration: action.value }
    default:
      throw new Error("Unknown action for StateContext", action)
  }
}

export const StateContext = createContext(initialState)

const StateContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <StateContext.Provider value={{ state, dispatch }}>
      {children}
    </StateContext.Provider>
  )
}

export default StateContextProvider

import React, { useEffect, useState, useRef } from "react"

import Scroll from "components/Scroll"
import ShowImage from "components/ShowImage"
import Header from "components/Header"
import Footer from "components/Footer"

const Content = props => {
  const container = useRef(null)
  const [isHome, setIsHome] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setIsHome(props.location.pathname === "/")
    }, 3000)
  }, [props.location])

  return (
    <>
      {/* SCROLL */}
      <Scroll
        container={container}
        callbacks={props.location}
        isMobile={props.isMobile}
      />

      {/* SHOW IMAGE */}
      <ShowImage location={props.location} />

      {/* HEADER */}
      <Header />

      <div
        id="container"
        ref={el => (container.current = el)}
        className={`${
          isHome ? "home h-screen" : "min-h-screen"
        } relative overflow-hidden`}
        data-scroll-container=""
        data-scroll-section=""
      >
        {/* MAIN */}
        <main id="main" className="relative z-10">
          {props.children}
        </main>

        {/* FOOTER */}
        <Footer isHome={isHome} />
      </div>
    </>
  )
}

export default Content

import { useEffect } from "react"

const Fallback = ({ setIsLoaded }) => {
  useEffect(() => {
    // console.log("Fallback mounted")

    return () => {
      setIsLoaded(true)
      // console.log("Fallback unmounted")
    }
  })

  return null
}

export default Fallback

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "styles/app.scss"
// import "locomotive-scroll/dist/locomotive-scroll.min.css"
import { setTheme } from "utils/Theme"

import pageRoot from "components/wrapRootElement"
import pageWrapper from "components/wrapPageElement"

export const wrapRootElement = pageRoot
export const wrapPageElement = pageWrapper

const updateFavicon = theme => {
  const faviconEL = document.querySelector('link[rel="icon"]')
  faviconEL.href = "/icon-light.png"
}

export const onRouteUpdate = props => {}

export const onInitialClientRender = () => {
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  let vh = window.innerHeight * 0.01
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty("--vh", `${vh}px`)

  updateFavicon()

  const themeLocalStorage = localStorage.getItem("theme")

  if (!themeLocalStorage) {
    setTheme("theme-dark")
  } else {
    setTheme(themeLocalStorage)
  }
}

export const onServiceWorkerUpdateFound = () => {
  if (window.localStorage) {
    window.localStorage.setItem("sw-updated", "true")
  }
}

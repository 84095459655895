import React from "react"

const Banner = ({ firstWord, secondWord, scrollTarget = "banner" }) => {
  return (
    <div
      id={scrollTarget}
      className={`${
        scrollTarget !== "banner-footer" ? "" : "mb-24 md:mb-32"
      } font-primary-bold leading-none uppercase text-6xl md:text-7xl whitespace-no-wrap`}
    >
      {Array(2)
        .fill()
        .map((_, lineIndex) => (
          <h2
            key={lineIndex}
            className="relative -ml-32 md:-ml-64 -pl-12 opacity-0 z-10 animation-1s animation-fill-both animation-once"
            data-banner=""
            data-banner-footer={`${
              scrollTarget === "banner-footer" ? true : false
            }`}
            data-scroll=""
            data-scroll-direction="horizontal"
            data-scroll-speed={lineIndex % 2 === 1 ? 10 : -10}
            data-scroll-target={`#${scrollTarget}`}
          >
            {Array(10)
              .fill()
              .map((_, wordsIndex) => {
                if (lineIndex % 2 === 1) {
                  return (
                    <span key={wordsIndex}>
                      <span className="u-outline pr-2 transition duration-500 ease-in-out">
                        {secondWord}
                      </span>
                      <span className="pr-2">{firstWord}</span>
                    </span>
                  )
                } else {
                  return (
                    <span key={wordsIndex}>
                      <span className="pr-2">{secondWord}</span>
                      <span className="u-outline pr-2 transition duration-500 ease-in-out">
                        {firstWord}
                      </span>
                    </span>
                  )
                }
              })}
          </h2>
        ))}
    </div>
  )
}

export default Banner

import React from "react"
import Banner from "components/Banner"

const Footer = ({ isHome }) => {
  const handleMouseOver = () => {
    document
      .querySelector("#icon__cursor-hand")
      .classList.add("icon__cursor--show")
  }
  const handleMouseOut = () => {
    document
      .querySelector("#icon__cursor-hand")
      .classList.remove("icon__cursor--show")
  }

  return (
    <footer
      className={`${isHome ? "hidden md:block absolute bottom-0 " : ""}
      w-full z-20 flex flex-col p-6 md:p-10 font-secondary text-sm`}
    >
      {!isHome && (
        <Banner
          firstWord="Contact"
          secondWord="Me"
          scrollTarget="banner-footer"
        />
      )}
      <div className="flex flex-col-reverse md:flex-row text-center md:text-left justify-between w-full items-center overflow-hidden">
        <div>
          <p>
            <span className="block" data-footer-credits="">
              Designed and developed by Brieuc Caillot
            </span>
            <span className="block" data-footer-credits="">
              © {new Date().getFullYear()} All rights reserved.
            </span>
          </p>
        </div>
        <ul
          className="relative overflow-hidden flex items-center pt-2 pb-2 md:pl-16"
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        >
          <li className="absolue pr-3 sm:pr-5" data-footer-social="">
            <a
              href="https://github.com/BrieucKyo"
              target="_blank"
              rel="noreferrer"
            >
              Github
            </a>
          </li>
          <li className="absolue px-3 sm:px-5" data-footer-social="">
            <a
              href="https://twitter.com/BrieucCaillot"
              target="_blank"
              rel="noreferrer"
            >
              Twitter
            </a>
          </li>
          <li className="absolue pl-3 sm:pl-5" data-footer-social="">
            <a
              href="mailto:brieucdeveloper@gmail.com"
              target="_blank"
              rel="noreferrer"
            >
              Email
            </a>
          </li>
        </ul>
      </div>
    </footer>
  )
}

export default Footer

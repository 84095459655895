import React, { useContext } from "react"
import TransitionLink from "gatsby-plugin-transition-link"

import { StateContext } from "components/Context"
import { Logo, ThemeIcon } from "components/Icons"

const Header = () => {
  const { dispatch } = useContext(StateContext)

  return (
    <header className="fixed top-0 p-6 md:p-10 w-screen flex justify-between z-20 font-primary text-sm opacity-0 pointer-events-none animation-fadeIn animation-once animation-fill-both animation-2s animation-delay-3s">
      {/* LOGO */}
      <div className="pointer-events-auto">
        <TransitionLink
          to={`/`}
          activeClassName="pointer-events-none"
          exit={{
            length: 5,
          }}
          entry={{
            delay: 5,
          }}
        >
          <Logo height="40" />
        </TransitionLink>
      </div>

      <div className="flex font-secondary items-center pointer-events-auto">
        <nav className="pr-3">
          {/* ABOUT */}
          <TransitionLink
            className="px-3 sm:px-5"
            draggable="false"
            to={`/about/`}
            exit={{
              length: 5,
            }}
            entry={{
              delay: 5,
            }}
          >
            About
          </TransitionLink>

          {/* WORKS */}
          <TransitionLink
            className="px-3 sm:px-5"
            draggable="false"
            to={`/works/`}
            exit={{
              length: 5,
            }}
            entry={{
              delay: 5,
            }}
          >
            Works
          </TransitionLink>
        </nav>

        {/* CHANGE THEME */}
        <ThemeIcon onClick={() => dispatch({ type: "SET_THEME" })} />
      </div>
    </header>
  )
}

export default Header

import * as THREE from "three"
import React, { useRef, useMemo, useEffect, useState } from "react"
import { extend, useThree, useFrame } from "react-three-fiber"
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer"
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass"
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass"
import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass"
import { FilmPass } from "three/examples/jsm/postprocessing/FilmPass"
import { GlitchPass } from "./post/Glitchpass"
import { WaterPass } from "./post/Waterpass"
import { Store } from "utils/Store"

extend({
  EffectComposer,
  ShaderPass,
  RenderPass,
  WaterPass,
  UnrealBloomPass,
  FilmPass,
  GlitchPass,
})

const Effects = () => {
  const composer = useRef()
  const [theme, setTheme] = useState(null)
  const { scene, gl, size, camera } = useThree()
  const aspect = useMemo(() => new THREE.Vector2(size.width, size.height), [
    size,
  ])
  useEffect(() => void composer.current.setSize(size.width, size.height), [
    size,
  ])

  useFrame(() => {
    Store.theme !== null ? setTheme(Store.theme) : setTheme("theme-dark")
    composer.current.render()
  }, 1)

  return (
    <effectComposer ref={composer} args={[gl]}>
      <renderPass attachArray="passes" scene={scene} camera={camera} />
      <waterPass attachArray="passes" factor={0.7} />
      {theme === "theme-dark" && (
        <unrealBloomPass attachArray="passes" args={[aspect, 2.5, 1, 0]} />
      )}
      {/*<glitchPass attachArray="passes" factor={down ? 1 : 0} />*/}
    </effectComposer>
  )
}

export default Effects

import React from "react"

export const Logo = ({ className, height, onClick }) => (
  <svg
    version="1.1"
    className={className}
    onClick={onClick}
    height={height || 80}
    viewBox="0 0 566.1 389.1"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <title>Brieuc Caillot</title>
    <desc>logo</desc>
    <g>
      <path
        d="M45.1,168.7c0.6,4.4-0.1,7.1-2.1,8.2c-2,1.1-5.7,0.9-11-0.7c-9.4-2.5-16.6-7.7-21.6-15.5c-5-7.8-8-16.6-8.9-26.3
		c-0.9-9.7,0-19.4,2.8-29.1c2.8-9.7,7.4-17.9,13.6-24.4c11.6-16.9,27.3-31.3,47-43c19.7-11.7,41-20.7,63.9-26.8
		c22.9-6.1,46.1-9.6,69.6-10.3C221.8-0.1,243.2,2,262.4,7c19.3,5,35.3,12.8,48.2,23.3c12.8,10.5,19.9,23.9,21.2,40.2
		c0.9,11.3-1,23.3-5.9,36.2c-4.9,12.9-11.8,25.4-20.9,37.6c-9.1,12.2-20,23.7-32.7,34.5c-12.7,10.8-26.2,19.8-40.7,27l8.5,2.8
		c13.8,5.6,23.5,13,29.1,22.1c5.6,9.1,7.8,19.1,6.6,30.1c-1.3,11-5.4,22.3-12.5,34.1c-7,11.8-16.2,23-27.5,33.8
		c-11.3,10.8-24.3,20.6-38.9,29.4c-14.7,8.8-30.2,15.7-46.7,20.7c-16.5,5-33.5,7.7-51.1,8c-17.6,0.3-34.6-2.7-51.2-8.9
		c-16-6.3-26.8-14.9-32.4-25.8c-5.6-11-6.7-24-3.3-39c0.3-1.2,1-3.3,2.1-6.1c1.1-2.8,2.4-5.3,3.8-7.5c1.4-2.2,2.8-3.4,4.2-3.5
		c1.4-0.2,2.7,1.8,4,5.9c1.6,5,3.8,9.5,6.6,13.4c2.8,3.9,5.8,7.2,8.9,9.9c3.1,2.7,6.1,4.9,8.9,6.6c2.8,1.7,5,2.9,6.6,3.5
		c11,5.3,23.5,7.9,37.6,7.8c14.1-0.2,28.4-2.1,42.8-5.9s28.5-8.9,42.2-15.5s25.6-13.9,35.7-22.1c10.1-8.1,17.7-16.6,22.9-25.4
		c5.2-8.8,6.7-17.1,4.5-24.9c-2.2-6.9-9.3-12.7-21.2-17.4c-12-4.7-26.1-8.3-42.5-10.8c-2.2,0.3-4.3,0.5-6.4,0.5s-4.2,0-6.4,0h-5.7
		c-5,20.7-8.8,38.9-11.3,54.5c-2.5,15.7-3.3,29.8-2.4,42.3c-0.9,2.2-4,2.8-9.3,1.9c-5.3-0.9-10.9-2.6-16.8-4.9
		c-5.9-2.3-11.3-5.1-16.1-8.2c-4.8-3.1-7.2-5.9-7.2-8.5c0.6-15.7,1.9-30.6,3.7-44.9c1.9-14.3,4.3-28,7.5-41.1
		c-8.4-3.4-15.5-8.8-21.3-16c-5.8-7.2-9.5-17.1-11-29.6c-0.3-3.1-0.2-6.6,0.2-10.3c0.5-3.8,1.2-6.7,2.1-8.7c0.9-2,2-2.7,3.3-2.1
		c1.2,0.6,2.7,3.8,4.2,9.4c1.6,7.2,4.7,13.2,9.4,18.1c4.7,4.9,11.5,8.5,20.5,11c6.5-23.2,13-43.9,19.6-62.3
		c6.5-18.3,10.7-34.5,12.6-48.6c1.3-1.9,4.3-1.7,9.1,0.5c4.8,2.2,9.8,5.2,15,8.9c5.2,3.8,9.8,7.8,13.7,12c3.9,4.2,5.7,7.4,5.4,9.6
		c-4.1,15.7-7.9,30.2-11.5,43.7c-3.6,13.5-7,26.3-10.1,38.5l11.3-1.4c13.2-2.8,25.7-7.8,37.6-15c11.9-7.2,22.4-15.8,31.5-25.9
		c9.1-10,16.4-21.1,21.9-33.1c5.5-12.1,8.5-24.5,9.2-37.4c0.3-10.3-1.4-18.4-5.2-24.2c-3.8-5.8-8.6-9.9-14.6-12.5
		c-6-2.5-12.4-3.8-19.3-4c-6.9-0.2-13.3,0.1-19.3,0.7c-19.7,2.2-39.9,7.1-60.4,14.6c-20.5,7.5-39.2,17.2-56.2,28.9
		c-16.9,11.8-31,25.4-42.3,40.9C53.1,133.7,46.6,150.5,45.1,168.7z"
      />
      <path
        d="M496.3,187c-3.1,2.8-6,4.5-8.7,5.2c-2.7,0.6-4.7,0.5-6.1-0.5c-1.4-0.9-2.1-2.6-2.1-4.9c0-2.4,1.2-5.1,3.8-8.2
		c2.5-3.1,6.3-8.6,11.3-16.4c5-7.8,10.1-16.7,15.3-26.6c5.2-9.9,9.9-20,14.3-30.6c4.4-10.5,7.1-20.2,8.2-29.1
		c1.1-8.9,0-16.2-3.3-21.9c-3.3-5.6-10-8.6-20-8.9c-9.7-0.3-20.1,3.7-31,12c-11,8.3-21.9,19.3-32.7,32.9
		c-10.8,13.6-21.3,29.2-31.5,46.8c-10.2,17.6-19.1,35.7-26.8,54.5c-7.7,18.8-13.8,37.4-18.3,55.9c-4.5,18.5-6.7,35.3-6.6,50.5
		c0.2,15.2,3,28,8.5,38.5c5.5,10.5,14.5,17.2,27,20c9.4,2.2,19.1,1.4,29.1-2.3c10-3.8,20-9.2,29.8-16.5c9.9-7.2,19.5-15.7,28.9-25.6
		c9.4-9.9,18.2-20,26.3-30.3c8.1-10.3,15.3-20.3,21.6-29.8c6.3-9.6,11.3-17.8,15-24.7c1.9-3.4,4.1-5.6,6.6-6.6
		c2.5-0.9,4.6-0.9,6.3,0c1.7,0.9,2.9,2.7,3.5,5.4c0.6,2.7,0,5.9-1.9,9.6c-4.4,9.4-10,19.6-16.9,30.6c-6.9,11-14.8,21.9-23.7,32.9
		c-8.9,11-18.6,21.5-29.1,31.7c-10.5,10.2-21.5,19.1-33.1,26.8c-11.6,7.7-23.6,13.8-36,18.3c-12.4,4.5-24.7,6.5-36.9,5.9
		c-21.3-0.6-37.8-6.7-49.6-18.1c-11.8-11.4-19.7-26.2-23.7-44.4c-4.1-18.2-4.7-38.6-1.9-61.3c2.8-22.7,8.3-45.8,16.5-69.3
		c8.1-23.5,18.6-46.4,31.3-68.6C372.4,97.6,386.5,78,402,61.1s32-30.2,49.6-40c17.5-9.7,35.2-13.8,53.1-12.2
		c16.6,1.6,29.4,6,38.3,13.2c8.9,7.2,14.7,16.1,17.4,26.8c2.7,10.7,2.7,22.4,0,35.2c-2.7,12.9-7,25.6-13.2,38.3
		c-6.1,12.7-13.6,24.8-22.6,36.2C515.8,170.1,506.3,179.5,496.3,187z"
      />
    </g>
  </svg>
)

export const ThemeIcon = ({ onClick }) => (
  <svg
    version="1.1"
    onClick={onClick}
    height={20}
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 13 13"
  >
    <g transform="translate(0,-284.29998)">
      <path
        d="m 6.1925827,284.83128 a 5.8147361,5.8147361 0 0 0 -5.65737969,5.82471 5.8148245,5.8148245 0 1 0 11.62964899,0 5.8147361,5.8147361 0 0 0 -5.9722693,-5.82471 z m 0.1574627,0.5387 v 10.57201 a 5.286213,5.286213 0 0 1 0,-10.57201 z"
        stroke="none"
      />
    </g>
  </svg>
)

import { useEffect, useRef } from "react"
import gsap from "gsap"

const ShowImage = ({ location }) => {
  const items = useRef(null)
  const currentItem = useRef(null)

  useEffect(() => {
    // console.log("Showimage mounted")
    setTimeout(() => {
      items.current = itemsElements()
      createEventsListeners()
      document.addEventListener("mousemove", onMouseMove, false)
    }, 7000)

    return () => {
      removeEventsListeners()
      document.removeEventListener("mousemove", onMouseMove, false)
      // console.log("Showimage unmounted")
    }
  }, [location])

  const itemsElements = () => {
    const items = [...document.querySelectorAll("[data-effect-text]")]

    return items.map((item, index) => ({
      element: item,
      img: document.querySelector(
        `img[data-effect-image="${item.getAttribute("data-effect-text")}"]`
      ),
      page: location.pathname,
    }))
  }

  const createEventsListeners = () => {
    if (!items.current) return
    items.current.forEach((item, index) => {
      item.element.addEventListener("mouseover", () => onMouseOver(item), false)
      item.element.addEventListener(
        "mouseleave",
        () => onMouseLeave(item),
        false
      )
    })
  }

  const removeEventsListeners = () => {
    if (!items.current) return
    items.current.forEach((item, index) => {
      item.element.removeEventListener("mouseover", onMouseOver, false)
    })
  }

  const onMouseOver = item => {
    // console.log("mouseover")
    gsap.to(item.img, 0.5, {
      autoAlpha: 1,
      ease: "power.easeOut",
    })
    currentItem.current = item
  }

  const onMouseLeave = item => {
    // console.log("mouseleave")
    gsap.to(item.img, 0.5, {
      autoAlpha: 0,
      ease: "power.easeOut",
    })
    currentItem.current = null
  }

  const onMouseMove = e => {
    if (!currentItem.current) return
    const isHomepage = currentItem.current.page === "/"
    let x =
      e.clientX -
      (currentItem.current.element.getBoundingClientRect().left +
        currentItem.current.img.width / 2)

    let y =
      e.clientY -
      (currentItem.current.element.getBoundingClientRect().top +
        currentItem.current.img.height / 2)

    gsap.to(currentItem.current.img, 1, {
      x,
      y: isHomepage ? y - currentItem.current.img.height / 2 : y,
      ease: "power.easeOut",
    })
  }

  return null
}

export default ShowImage

import { gsap } from "gsap"

const moveSparks = (sparks, movesForward = true) => {
  gsap.to(sparks.position, {
    duration: 2,
    ease: movesForward ? "power4.easeOut" : "power4.easeIn",
    z: movesForward ? 35 : 70,
  })
}

// LOADER TIMELINE
export const LoaderTimeline = tl => {
  tl.addLabel("loader")
  tl.fromTo(
    "#loader",
    {
      autoAlpha: 0,
    },
    {
      duration: 0.1,
      autoAlpha: 1,
      ease: "power4.easeIn",
    }
  )
  tl.fromTo(
    "[data-loader-line]",
    {
      autoAlpha: 0,
      y: "150%",
    },
    {
      duration: 1,
      delay: 2,
      autoAlpha: 1,
      ease: "power4.easeOut",
      stagger: {
        amount: 0.6,
      },
      y: 0,
    }
  )

  window.loaderTl = tl
}

// HOME TIMELINE
export const HomeTimeline = (logoMesh, sparks, isMobile) => {
  moveSparks(sparks, false)

  let tl = gsap.timeline({ defaults: { ease: "power.out" } })
  tl.addLabel("homepage")
  tl.to(logoMesh.position, {
    duration: 3,
    y: isMobile ? 12 : 0,
    z: isMobile ? -10 : 5,
  })
  tl.fromTo(
    ["[data-anim-line]"],
    {
      autoAlpha: 0,
      y: "120%",
    },
    {
      autoAlpha: 1,
      y: 0,
      stagger: {
        amount: 0.8,
      },
    }
  )

  return tl
}

// ABOUT TIMELINE
export const AboutTimeline = sparks => {
  moveSparks(sparks)

  let tl = gsap.timeline({ delay: 2, defaults: { ease: "power4.easeOut" } })
  tl.eventCallback("onStart", () => {
    document.querySelector(".c-image").classList.add("c-image--show")
  })
  tl.eventCallback("onReverseComplete", () => {
    document.querySelector(".c-image").classList.remove("c-image--show")
  })
  tl.addLabel("about")
  tl.fromTo(
    "[data-banner-footer='false']",
    {
      opacity: 0,
    },
    {
      opacity: 1,
      stagger: {
        amount: 0.6,
      },
    }
  )

  // SECTIONS
  document.querySelectorAll("[data-anim-section]").forEach(section => {
    tl.fromTo(
      section.querySelector("[data-anim-title]"),
      {
        autoAlpha: 0,
      },
      {
        autoAlpha: 1,
        duration: 0.1,
      },
      "-=0.3"
    )
    tl.fromTo(
      section.querySelectorAll("[data-anim-line]"),
      {
        autoAlpha: 0,
        y: 80,
      },
      {
        autoAlpha: 1,
        y: 0,
        stagger: { amount: 0.8 },
      }
    )
  })

  return tl
}

// WORKS TIMELINE
export const WorksTimeline = sparks => {
  moveSparks(sparks)

  let tl = gsap.timeline({ delay: 2, defaults: { ease: "power4.easeOut" } })
  tl.addLabel("works")
  document.querySelectorAll("[data-banner-footer='false']").forEach(project => {
    tl.fromTo(
      project,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        stagger: {
          amount: 0.6,
        },
      }
    )
  })

  tl.fromTo(
    "[data-anim-line]",
    {
      autoAlpha: 0,
      y: "120%",
    },
    {
      autoAlpha: 1,
      y: 0,
      stagger: {
        amount: 0.8,
      },
    }
  )

  return tl
}

// PROJECT TIMELINE
export const ProjectTimeline = sparks => {
  moveSparks(sparks)

  let tl = gsap.timeline({ delay: 2, defaults: { ease: "power4.easeOut" } })
  tl.eventCallback("onStart", () => {
    document
      .querySelectorAll(".c-image")
      .forEach(image => image.classList.add("c-image--show"))
  })
  tl.eventCallback("onReverseComplete", () => {
    document
      .querySelectorAll(".c-image")
      .forEach(image => image.classList.remove("c-image--show"))
  })
  tl.addLabel("project")
  tl.fromTo(
    "[data-anim-project-title]",
    {
      autoAlpha: 0,
    },
    {
      autoAlpha: 1,
      duration: 0.1,
    },
    "project"
  )
  tl.fromTo(
    ["[data-anim-project-link]"],
    {
      autoAlpha: 0,
    },
    {
      autoAlpha: 1,
      stagger: {
        amount: 0.6,
      },
    },
    "project"
  )

  // SECTIONS
  document.querySelectorAll("[data-anim-section]").forEach(section => {
    tl.fromTo(
      section.querySelector("[data-anim-title]"),
      {
        autoAlpha: 0,
      },
      {
        autoAlpha: 1,
        duration: 0.1,
      },
      "-=0.3"
    )
    tl.fromTo(
      section.querySelectorAll("[data-anim-line]"),
      {
        autoAlpha: 0,
        y: 80,
      },
      {
        autoAlpha: 1,
        y: 0,
        stagger: { amount: 0.8 },
      }
    )
  })
  document.querySelectorAll("[data-banner-footer='false']").forEach(project => {
    tl.fromTo(
      project,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        stagger: {
          amount: 0.6,
        },
      }
    )
  })
  tl.fromTo(
    "[data-other-project]",
    {
      autoAlpha: 0,
    },
    {
      autoAlpha: 1,
    }
  )

  return tl
}

// FOOTER TIMELINE
export const FooterTimeline = () => {
  let tl = gsap.timeline()
  const bannerFooter = document.querySelectorAll("[data-banner-footer='true']")

  tl.addLabel("footer")
  if (bannerFooter.length) {
    tl.fromTo(
      bannerFooter,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        stagger: {
          amount: 0.6,
        },
      }
    )
  }
  tl.fromTo(
    ["[data-footer-credits]"],
    {
      autoAlpha: 0,
      y: 50,
    },
    {
      ease: "power4.easeOut",
      autoAlpha: 1,
      y: 0,
      stagger: {
        amount: 0.2,
      },
    },
    "footer"
  )
  tl.fromTo(
    ["[data-footer-social]"],
    {
      autoAlpha: 0,
      y: 30,
    },
    {
      duration: 1.5,
      ease: "bounce.out",
      autoAlpha: 1,
      y: 0,
      stagger: {
        amount: 0.6,
      },
    },
    "footer+=0.1"
  )

  window.footerTl = tl

  return tl
}

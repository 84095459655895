/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import * as THREE from "three"
import React, { useEffect, useRef, useState, Suspense } from "react"
import { Canvas } from "react-three-fiber"
// import {
// PositionalAudio
// } from "drei"

import Loading from "components/Loading"
import Content from "components/Content"
import Fallback from "components/Fallback"
import Particles from "components/Three/Particles"
import Effects from "components/Three/Effects"
import Logo from "components/Three/Logo"
import Sparks from "components/Three/Sparks"
import Cursor from "components/Three/Cursor"
// import Plane from "components/Three/Plane"

const Layout = props => {
  const cursorDom = useRef(null)
  const isMobile = useRef(null)
  const [isLoaded, setIsLoaded] = useState(false)
  const spotLight = useRef(null)
  const logoMesh = useRef(null)
  const sparks = useRef(null)
  const mousePos = useRef({
    x: 0,
    y: 0,
    clientX: 0,
    clientY: 0,
    normalizedMousePos: { x: 0, y: 0 },
  })

  const onMouseMove = ({ clientX, clientY }) => {
    if (typeof window !== "undefined") {
      mousePos.current.x = clientX - window.innerWidth / 2
      mousePos.current.y = clientY - window.innerHeight / 2
      mousePos.current.clientX = clientX
      mousePos.current.clientY = clientY
      // mousePos.current.normalizedMousePos.x =
      //   (clientX / window.innerWidth) * 2 - 1
      // mousePos.current.normalizedMousePos.y =
      //   -(clientY / window.innerHeight) * 2 + 1
    }
  }

  useEffect(() => {
    // console.log("Layout mounted")
    document.addEventListener("mousemove", onMouseMove, false)

    if (typeof window !== "undefined") {
      isMobile.current =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
          navigator.userAgent
        ) || window.innerWidth < 768
    }

    return () => {
      // console.log("Layout unmounted")
    }
  }, [])

  return (
    <>
      {/* LOADING */}
      <Loading
        isLoaded={isLoaded}
        logoMesh={logoMesh}
        sparks={sparks}
        isMobile={isMobile}
      />

      {/* CONTENT */}
      <Content location={props.location} isMobile={isMobile.current}>
        {props.children}
      </Content>

      {/* CURSOR */}
      <div>
        <div
          role="button"
          ref={cursorDom}
          id="cursor"
          className="absolute top-0 left-0 opacity-0 h-full w-full overflow-hidden pointer-events-none"
          onMouseMove={onMouseMove}
        ></div>
      </div>

      {/* CANVAS */}
      <div className="fixed top-0 left-0 h-screen w-full -z-10">
        <Canvas
          concurrent
          colorManagement
          pixelRatio={Math.min(
            2,
            isMobile.current && typeof window !== "undefined"
              ? window.devicePixelRatio
              : 1
          )}
          camera={{ fov: 100, position: [0, 0, 30] }}
          onCreated={({ gl, events }) => {
            gl.toneMapping = THREE.Uncharted2ToneMapping
            delete events.onGotPointerCaptureLegacy
          }}
        >
          <Suspense
            concurrent
            fallback={<Fallback setIsLoaded={setIsLoaded} />}
          >
            {/* <Plane /> */}
            <mesh ref={el => (logoMesh.current = el)} position={[0, 0, 35]}>
              {/* <PositionalAudio url="/sound2.mp3" /> */}
              <Particles count={1500} mousePos={mousePos} />
              <Effects />

              {/* <ambientLight intensity={4} /> */}
              <spotLight
                ref={spotLight}
                mousePos={mousePos}
                position={[0, 0, 40]}
                enumbra={0.2}
                castShadow
              />
              <Logo />
            </mesh>
            <mesh ref={el => (sparks.current = el)} position={[0, 0, 70]}>
              <Sparks
                count={15}
                mousePos={mousePos}
                colors={[
                  "#A2CCB6",
                  "#FCEEB5",
                  "#EE786E",
                  "#e0feff",
                  "lightpink",
                ]}
              />
            </mesh>
            {isMobile.current !== null && !isMobile.current && (
              <Cursor
                mousePos={mousePos}
                portal={cursorDom}
                onMouseMove={onMouseMove}
              />
            )}
          </Suspense>
        </Canvas>
      </div>
    </>
  )
}

export default Layout

import React, { useEffect, useContext, useState, useMemo } from "react"
import { gsap } from "gsap"
import { StateContext } from "components/Context"
import { LoaderTimeline } from "utils/Animations"

const Loading = ({ isLoaded, logoMesh, sparks, isMobile }) => {
  const [timelineIsCompleted, setTimelineIsCompleted] = useState(false)
  const { dispatch } = useContext(StateContext)

  const loaderTl = useMemo(
    () =>
      gsap.timeline({
        paused: true,
        defaults: { ease: "power4.easeOut" },
      }),
    []
  )

  useEffect(() => {
    // console.log("Loading mount")
    document.body.classList.add("overflow-hidden")
    LoaderTimeline(loaderTl)

    loaderTl
      .play()
      .eventCallback("onComplete", () => {
        setTimelineIsCompleted(true)
      })
      .eventCallback("onReverseComplete", () => {
        dispatch({ type: "SET_LOADED", value: true })
      })
  }, [])

  useEffect(() => {
    dispatch({ type: "SET_MOBILE", value: isMobile.current })
    dispatch({ type: "SET_LOGOMESH", value: logoMesh.current })
    dispatch({ type: "SET_SPARKS", value: sparks.current })
    if (isLoaded) {
      if (timelineIsCompleted) {
        window.sparks = sparks.current
        loaderTl.reverse()
      }
    }

    return () => {
      // console.log("Loading unmount")
    }
  }, [isLoaded, isMobile, logoMesh, dispatch, timelineIsCompleted])

  return (
    <div
      id="loader"
      className="absolute flex u-current-theme justify-center top-0 z-50 w-screen h-screen cursor-wait"
    >
      <div className="absolute uppercase text-center text-3xl flex inset-y-0 items-center">
        <div>
          <div className="overflow-hidden h-8">
            <h2 className="invisible" data-loader-line="">
              Brieuc
            </h2>
          </div>
          <div className="overflow-hidden h-8">
            <h2 className="invisible" data-loader-line="">
              Caillot
            </h2>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Loading

import React, { useCallback, useEffect, useRef, useState } from "react"
import { useFrame } from "react-three-fiber"
import { Html } from "drei"
import { gsap } from "gsap"
import lerp from "lerp"

const Cursor = ({ mousePos, portal }) => {
  const circle = useRef()
  const dot = useRef()
  const scale = useRef(1)
  const lastScale = useRef(1)

  useEffect(() => {
    gsap.to("#cursor", {
      duration: 2,
      delay: 6,
      autoAlpha: 1,
    })
  }, [])

  const [lastMousePos, setLastMousePos] = useState({
    dot: { x: 0, y: 0 },
    circle: { x: 0, y: 0 },
  })

  const update = useCallback(() => {
    setLastMousePos({
      dot: {
        x: lerp(
          lastMousePos.dot.x,
          mousePos.current.x - dot.current.getBoundingClientRect().width / 2,
          0.5
        ),
        y: lerp(
          lastMousePos.dot.y,
          mousePos.current.y - dot.current.getBoundingClientRect().height / 2,
          0.5
        ),
      },
      circle: {
        x: lerp(
          lastMousePos.circle.x,
          mousePos.current.x - circle.current.getBoundingClientRect().width / 2,
          0.3
        ),
        y: lerp(
          lastMousePos.circle.y,
          mousePos.current.y -
            circle.current.getBoundingClientRect().height / 2,
          0.3
        ),
      },
    })

    lastScale.current = lerp(lastScale.current, scale.current, 0.15)
    dot.current.style.transform = `translateX(${lastMousePos.dot.x}px) translateY(${lastMousePos.dot.y}px)`
    circle.current.style.transform = `translateX(${lastMousePos.circle.x}px) translateY(${lastMousePos.circle.y}px) scale(${lastScale.current})`
  }, [mousePos, lastMousePos])

  useFrame(() => update())

  return (
    <Html
      className="hidden md:block"
      prepend
      zIndexRange={[60, 0]}
      portal={portal}
    >
      {/* OTHERS */}
      <div ref={dot} className="relative top-0 left-0 z-50 pointer-events-none">
        {/* DOT */}
        <div
          id="cursor__dot"
          className="icon__cursor icon__cursor--show rounded-full"
        ></div>
        <div id="icon__cursor-hand" className="icon__cursor">
          <span role="img" aria-label="hi">
            👋
          </span>
        </div>
        <div id="icon__cursor-eye" className="icon__cursor">
          <img
            src="https://hoang.moe/wp-content/themes/degamin/dist/public/icons/eye.svg"
            alt="eye-svg"
          />
        </div>
        <div id="icon__cursor-arrow" className="icon__cursor icon_reverser">
          <svg
            width={18}
            height={16}
            viewBox="0 0 18 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="svg"
          >
            <path d="M0 8H16" strokeWidth={2} />
            <path d="M10 2L16 8L10 14" strokeWidth={2} strokeLinecap="square" />
          </svg>
        </div>
      </div>
      {/* CIRCLE */}
      <div
        ref={circle}
        id="cursor__circle"
        className="rounded-full pointer-events-none"
      ></div>
    </Html>
  )
}

export default Cursor

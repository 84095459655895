import { useEffect } from "react"
import LocomotiveScroll from "locomotive-scroll"

const Scroll = ({ container, isMobile, callbacks }) => {
  useEffect(() => {
    if (window.locoscroll || isMobile === null) return
    let locomotiveScroll = new LocomotiveScroll({
      el: container.current,
      smooth: true,
      smoothMobile: isMobile,
      getDirection: true,
      repeat: true,
      touchMultiplier: isMobile ? 1 : 2.5,
      lerp: isMobile ? 0.3 : 0.15,
    })

    locomotiveScroll.update()

    // Exposing to the global scope for ease of use.
    window.locoscroll = locomotiveScroll
    window.dispatchEvent(new Event("resize"))

    locomotiveScroll.on("scroll", func => {
      // Update `data-direction` with scroll direction.
      document.documentElement.setAttribute("data-direction", func.direction)
    })
  }, [container, isMobile, callbacks])
  return null
}

export default Scroll
